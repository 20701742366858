.services{
    .section-1{
        border-radius: 5px;
        min-height: calc(100vh - 150px);
        margin-bottom: 50px;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 768px) {
            min-height: calc(100vh - 100px);
            background-color: #ffcd00;
        }
        .row.bg{
            min-height: calc(100vh - 150px);
            position: absolute;
            top: 0;
            width: 100%;
            @media screen and (max-width: 768px) {
                min-height: auto;
                position: relative;
            }
            .col-md-4{
                text-align: center;
            }
        }
        .service{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: calc(100vh - 150px);
            @media screen and (max-width: 768px) {
                min-height: calc(100vh - 100px);
            }
        }.service-content{
            min-height: calc(100vh - 150px);
            @media screen and (max-width: 768px) {
                min-height: calc(100vh - 100px);
            }
            justify-content: center;
            align-items: center;
            display: flex;
            width: 100%;
            z-index: 10000;
            margin-left: -15px;
            @media screen and (max-width: 768px) {
                margin-left: 0;
            }
        }
        .bg{
            .bg-1{
                background-color: #ffcd00;
                z-index: -1;
            }
            .bg-2{
                background-color: #ffde59;
                z-index: -1;
            }
            .bg-3{
                background-color: #ffe788;
                z-index: -1;
            }
        }
        h1 {
            font-size: 40px;
            font-weight: 700;
            text-align: center;
            color: #24282b;
            margin-bottom: 20px;
            text-transform: uppercase;
            z-index: 100;
        }
        h4{
            font-weight: 600;
            color: #24282b;
            font-size: 26px;
        }
        p{
            font-size: 18px;
            font-weight: 500;
        }
        .services-content{
            z-index: 100;
        }
        .line{
            position: absolute;
            top: 50%;
            left: 50%;
            pointer-events: none;
            width: 0;
            height: 0;
            -webkit-transition: 0.55s cubic-bezier(0.62, 0.02, 0.34, 1);
            -moz-transition: 0.55s cubic-bezier(0.62, 0.02, 0.34, 1);
            transition: 0.55s cubic-bezier(0.62, 0.02, 0.34, 1);
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                -webkit-transform-origin: 50% 0;
                -moz-transform-origin: 50% 0;
                -ms-transform-origin: 50% 0;
                -o-transform-origin: 50% 0;
                transform-origin: 50% 0;
                -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.52, 0.01, 0.16, 1), background 0.26s linear;
                -moz-transition: -moz-transform 0.9s cubic-bezier(0.52, 0.01, 0.16, 1), background 0.26s linear;
                transition: transform 0.9s cubic-bezier(0.52, 0.01, 0.16, 1), background 0.26s linear;
                width: 300vw;
                height: 300vw;
                margin-left: -150vw;
                background-color: #949ea7;
                transform: rotate(0deg);
            }
        }
        .btn{
            background-color: transparent;
            border: 3px solid white;
            color: #24282b;
            font-size: 18px;
            font-weight: 700;
            padding: 10px 20px;
            border-radius: 0px;
            text-transform: uppercase;
            position: relative;
            transition: all 0.3s ease;
            z-index: 100;
            &:hover{
                background-color: white;
                color: #24282b;
            }
        }
    }
}