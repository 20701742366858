.work-detail {
    .section-1 { 
        .back-proj{
          @media screen and (min-width: 767px) {
            position: absolute;
            top: 40px;
          }
        }
        h4{
          font-size: 18px;
          font-weight: 600;
        }
        background-color: white;
        border-radius: 5px;
        min-height: calc(100vh - 150px);
        margin-bottom: 50px;
        height: calc(100vh - 150px);
        position: relative;
        @media screen and (max-width: 768px) {
          min-height: calc(100vh - 70px);
          height: 100%;
        }
        .proj-title{
          @media screen and (min-width: 768px) {
            height: 100%;
          }
        }
        .proj-img{
          @media screen and (min-width: 768px) {
            height: 100%;
          }
        }
        h1 {
            font-size: 40px;
            font-weight: 600;
            color: #24282b;
            margin-bottom: 20px;
            // text-transform: uppercase;
            position: relative;
            display: inline-block;
            transition: all 0.3s ease;
            span {
                transition: all 0.3s ease;
                color: #ffcd00;
            }
        }
    }
    .swiper {
        width: 100%;
        height: 100%;
        margin-bottom: 50px;
        border-radius: 10px;
        z-index: 0;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
    }
    .swiper-pagination-bullet-active {
        background-color: #ffcd00;
    }
    .swiper-button-next,
    .swiper-button-prev {
        color: #ffcd00;
    }
    .proj-attributes {
        h4 {
            font-size: 32px;
            font-weight: 700;
            color: #ffcd00;
            margin-bottom: 20px;
            position: relative;
            display: inline-block;
            transition: all 0.3s ease;
        }
        p {
            line-height: 2;
            
        }
        .desc{
          font-weight: 500;
          font-size: 18px;
          text-align: justify;
        }
        .attr{
          font-size: 20px;
          font-weight: 700;
        }
    }
}
