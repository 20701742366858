.works {
    max-height: calc(100vh - 150px);
    overflow: hidden;
    .work-imgs{
        height: calc(100vh - 150px);
        img{
            object-fit: cover;
        }
        @media screen and (max-width: 768px){
            height: calc(50vh - 100px);
        }
    }
    .work-list {
        height: calc(100vh - 150px);
        overflow-y: auto;
        @media screen and (max-width: 768px){
            // height: calc(50vh - 100px);
            &::-webkit-scrollbar {
                width: 0px !important;
            }
        }
        h1{
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 20px;
            color: #24282b;
        }
        .work{
            border-bottom: 2px solid #41464c;
            &:hover{
                h3{
                    position: relative;
                    display: inline-block;
                    &::after{
                        width: 100%;
                    }
                }
            }
            h3{
                font-size: 30px;
                font-weight: 500;
                margin-bottom: 20px;
                color: #24282b;
                &::after{
                    content: "";
                    height: 3px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: #ffcd00;
                    width: 0%;
                    transition: all 0.3s ease;
                }
            }
            p{
                font-size: 14px;
                margin-bottom: 20px;
                color: #41464c;
                font-style: italic;
            }
        }
        &::-webkit-scrollbar {
            width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #888;
            border-radius: 50px;

        }

        &::-webkit-scrollbar-thumb {
            background: #ffcd00;
            border-radius: 50px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #ffcd00;
        }
    }
}
