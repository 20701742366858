.about{
    .section-2{
        scroll-margin-top: 200px;
        background-color: white;
        border-radius: 5px;
        // min-height: calc(100vh - 120px);
        margin-bottom: 50px;
        position: relative;
        h1{
            font-size: 40px;
            font-weight: 600;
            color: #24282b;
            margin-bottom: 20px;
            // text-transform: uppercase;
            position: relative;
            display: inline-block;
            transition: all 0.3s ease;
            span{
                transition: all 0.3s ease;
                color: #ffcd00;
            }
        }
        .detail{
            img{
                width: 100%;
                height: 95%;
                border-radius: 5px;
            }
        }
        p{
            font-weight: 500;
            font-size: 18px;
            text-align: justify;
            line-height: 2.2;
        }
    }
    .section-3{
        scroll-margin-top: 200px;
        background-color: white;
        border-radius: 5px;
        // min-height: calc(100vh - 120px);
        margin-bottom: 50px;
        position: relative;
        h1{
            font-size: 40px;
            font-weight: 600;
            color: #24282b;
            margin-bottom: 20px;
            // text-transform: uppercase;
            position: relative;
            display: inline-block;
            transition: all 0.3s ease;
            span{
                transition: all 0.3s ease;
                color: #ffcd00;
            }
        }
        h3{
            font-size: 25px;
            font-weight: 600;
            color: #24282b;
            margin-bottom: 10px;
            position: relative;
            display: block;
            transition: all 0.3s ease;;
        }
        p.name{
            margin-bottom: 20px;
            font-size: 22px;
            transition: all 0.3s ease;
            color: #24282b;
            position: relative;
            display: inline-block;
            z-index: 10;
            &::before{
                content: '';
                position: absolute;
                height: 100%;
                top: 0;
                left: 0;
                width: 50%;
                background-color: #ffcd00;
                transition: all 0.3s ease;
                border-radius: 2px;
                opacity: 0.5;
                z-index: -1;
            }
        }
        .detail{
            img{
                width: 75%;
                border-radius: 5px;
                
            }
            @media (max-width: 992px){
                img{
                    width: 100%;
                }
                
            }
        }
        p{
            font-weight: 500;
            font-size: 18px;
            text-align: justify;
            line-height: 2.2;
        }
    }
    .section-4{
        scroll-margin-top: 200px;
        background-color: white;
        border-radius: 5px;
        // min-height: calc(100vh - 120px);
        margin-bottom: 50px;
        position: relative;
        h1{
            font-size: 40px;
            font-weight: 600;
            color: #24282b;
            margin-bottom: 20px;
            // text-transform: uppercase;
            position: relative;
            display: inline-block;
            transition: all 0.3s ease;
            span{
                transition: all 0.3s ease;
                color: #ffcd00;
            }
        }
        .detail{
            img{
                width: 100%;
                height: 400px;
                object-fit: cover;
                transition: all 0.3s ease;
                &:hover{
                    transform: scale(1.05);
                }
            }
            .team{
                display: flex;
                justify-content: center;
                // align-items: center;
                flex-direction: column;
                margin-bottom: 20px;
            }
        }
        p{
            font-weight: 600;
            font-size: 18px;
            text-align: justify;
            margin-top: 10px;
            margin-bottom: 5px;
        }
        small{
            color: #41464c;
            font-weight: 400;
            font-style: italic;
        }
    }
}