.home {
    min-height: calc(100vh - 100px);
    p{
        color: #24282b;
    }
    .section-1 {
        background-color: #ffcd00;
        border-radius: 5px;
        height: calc(100vh - 150px);
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 768px) {
            height: calc(100vh - 100px);
        }
        .content{
            z-index: 2;
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .bg{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #ffcd00;
            z-index: 1;
            opacity: .4;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            filter: brightness(1);
            opacity: .4;
            // jump image
            animation: jump 1s infinite;
            @keyframes jump {
                0% {
                    transform: translateY(0);
                }
                25% {
                    transform: translateY(-2px);
                }
                50% {
                    transform: translateY(0);
                }
                75% {
                    transform: translateY(2px);
                }
                100% {
                    transform: translateY(0);
                }
            }
        }
        h1 {
            font-size: 40px;
            font-weight: 700;
            text-align: center;
            color: #24282b;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
        .scroll-down {
            cursor: pointer;
            position: absolute;
            bottom: 30px;

            .m_scroll_arrows {
                display: block;
                width: 5px;
                height: 5px;
                -ms-transform: rotate(45deg); /* IE 9 */
                -webkit-transform: rotate(
                    45deg
                ); /* Chrome, Safari, Opera */
                transform: rotate(45deg);

                border-right: 2px solid #24282b;
                border-bottom: 2px solid #24282b;
                margin: 0 0 3px 4px;

                width: 16px;
                height: 16px;
            }

            .unu {
                margin-top: 1px;
            }

            .unu,
            .doi,
            .trei {
                -webkit-animation: mouse-scroll 1s infinite;
                -moz-animation: mouse-scroll 1s infinite;
                animation: mouse-scroll 1s infinite;
            }

            .unu {
                -webkit-animation-delay: 0.1s;
                -moz-animation-delay: 0.1s;
                -webkit-animation-direction: alternate;

                animation-direction: alternate;
                animation-delay: alternate;
            }

            .doi {
                -webkit-animation-delay: 0.2s;
                -moz-animation-delay: 0.2s;
                -webkit-animation-direction: alternate;

                animation-delay: 0.2s;
                animation-direction: alternate;

                margin-top: -6px;
            }

            .trei {
                -webkit-animation-delay: 0.3s;
                -moz-animation-delay: 0.3s;
                -webkit-animation-direction: alternate;

                animation-delay: 0.3s;
                animation-direction: alternate;

                margin-top: -6px;
            }

            .mouse {
                height: 42px;
                width: 24px;
                border-radius: 14px;
                transform: none;
                border: 2px solid #24282b;
                top: 170px;
            }

            .wheel {
                height: 5px;
                width: 2px;
                display: block;
                margin: 5px auto;
                background: #24282b;
                position: relative;

                height: 4px;
                width: 4px;
                border: 2px solid #24282b;
                -webkit-border-radius: 8px;
                border-radius: 8px;
            }

            .wheel {
                -webkit-animation: mouse-wheel 0.6s linear infinite;
                -moz-animation: mouse-wheel 0.6s linear infinite;
                animation: mouse-wheel 0.6s linear infinite;
            }

            @-webkit-keyframes mouse-wheel {
                0% {
                    opacity: 1;
                    -webkit-transform: translateY(0);
                    -ms-transform: translateY(0);
                    transform: translateY(0);
                }

                100% {
                    opacity: 0;
                    -webkit-transform: translateY(6px);
                    -ms-transform: translateY(6px);
                    transform: translateY(6px);
                }
            }
            @-moz-keyframes mouse-wheel {
                0% {
                    top: 1px;
                }
                25% {
                    top: 2px;
                }
                50% {
                    top: 3px;
                }
                75% {
                    top: 2px;
                }
                100% {
                    top: 1px;
                }
            }
            @-o-keyframes mouse-wheel {
                0% {
                    top: 1px;
                }
                25% {
                    top: 2px;
                }
                50% {
                    top: 3px;
                }
                75% {
                    top: 2px;
                }
                100% {
                    top: 1px;
                }
            }
            @keyframes mouse-wheel {
                0% {
                    top: 1px;
                }
                25% {
                    top: 2px;
                }
                50% {
                    top: 3px;
                }
                75% {
                    top: 2px;
                }
                100% {
                    top: 1px;
                }
            }

            @-webkit-keyframes mouse-scroll {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: 0.5;
                }
                100% {
                    opacity: 1;
                }
            }
            @-moz-keyframes mouse-scroll {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: 0.5;
                }
                100% {
                    opacity: 1;
                }
            }
            @-o-keyframes mouse-scroll {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: 0.5;
                }
                100% {
                    opacity: 1;
                }
            }
            @keyframes mouse-scroll {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: 0.5;
                }
                100% {
                    opacity: 1;
                }
            }
        }
        #tsparticles {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            canvas {
                width: 100% !important;
                height: 100% !important;
                position: absolute !important;
                background-color: #ffcd00;
                border-radius: 10px;
            }
        }
    }
    .section-2{
        scroll-margin-top: 200px;
        background-color: #a0aab3;
        // background-color: #6c7379;
        border-radius: 5px;
        min-height: calc(100vh - 150px);
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 768px) {
            min-height: calc(100vh - 70px);
        }
        h1 {
            font-size: 40px;
            font-weight: 700;
            text-align: center;
            color: white;
            margin-bottom: 20px;
            text-transform: uppercase;
            z-index: 100;
        }
        .line{
            position: absolute;
            top: 50%;
            left: 50%;
            pointer-events: none;
            width: 0;
            height: 0;
            -webkit-transition: 0.55s cubic-bezier(0.62, 0.02, 0.34, 1);
            -moz-transition: 0.55s cubic-bezier(0.62, 0.02, 0.34, 1);
            transition: 0.55s cubic-bezier(0.62, 0.02, 0.34, 1);
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                -webkit-transform-origin: 50% 0;
                -moz-transform-origin: 50% 0;
                -ms-transform-origin: 50% 0;
                -o-transform-origin: 50% 0;
                transform-origin: 50% 0;
                -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.52, 0.01, 0.16, 1), background 0.26s linear;
                -moz-transition: -moz-transform 0.9s cubic-bezier(0.52, 0.01, 0.16, 1), background 0.26s linear;
                transition: transform 0.9s cubic-bezier(0.52, 0.01, 0.16, 1), background 0.26s linear;
                width: 300vw;
                height: 300vw;
                margin-left: -150vw;
                background-color: #949ea7;
                // background-color: #4f555b;
                transform: rotate(0deg);
            }
        }
        .recent-works{
            z-index: 100;
            gap: 30px;
            .btn{
                background-color: transparent;
                color: white;
                border: unset;
                font-size: 16px;
                font-weight: 500;
                padding: 10px 20px;
                border-radius: 5px;
                margin-top: 20px;
                cursor: pointer;
                transition: 1s;
                position: relative;
                &::after{
                    content: '->';
                    margin-left: 5px;
                    position: absolute;
                    top: 8.5px;
                }
                &:hover{
                    background-color: transparent;
                    color: #ffcd00;
                    // add arrow
                    margin-left: 10px;
                }
            }
            .work{
                width: 275px;
                height: 250px;
                border-radius: 5px;
                position: relative;
                &:hover > .work-content{
                    height: 250px;
                }
                img{
                    width: 275px;
                    height: 250px;
                    border-radius: 5px;
                    top: 0px;
                    left: 0px;
                    object-fit: cover;
                }
                .work-content{
                    filter: blur(0.5px);
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 275px;
                    height: 250px;
                    overflow: hidden;
                    border-radius: 5px;
                    transition: 0.5s;
                    background-color: #24282b42;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    z-index: 1000;
                    h1{
                        font-size: 20px;
                        font-weight: 700;
                        color: white;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .btn{
            background-color: transparent;
            border: 3px solid white;
            color: white;
            font-size: 14px;
            font-weight: 700;
            padding: 10px 20px;
            border-radius: 0px;
            text-transform: uppercase;
            position: relative;
            transition: all 0.3s ease;
            z-index: 100;
            &:hover{
                background-color: white;
                color: #24282b;
            }
        }
    }
    .section-3{
        scroll-margin-top: 200px;
        background-color: white;
        border-radius: 5px;
        // min-height: calc(100vh - 120px);
        margin-bottom: 50px;
        position: relative;
        h1{
            font-size: 40px;
            font-weight: 600;
            color: #24282b;
            margin-bottom: 20px;
            // text-transform: uppercase;
            position: relative;
            display: inline-block;
            transition: all 0.3s ease;
            span{
                transition: all 0.3s ease;
                color: #ffcd00;
            }
        }
        .detail{
            img{
                width: 75%;
                border-radius: 5px;
                
            }
            @media screen and (max-width: 768px){
                img{
                    width: 100%;
                }
                
            }
        }
        p{
            font-weight: 500;
            font-size: 18px;
            text-align: justify;
            line-height: 2.2;
        }
    }
    .section-4{
        scroll-margin-top: 200px;
        background-color: white;
        border-radius: 5px;
        // min-height: calc(100vh - 120px);
        margin-bottom: 50px;
        position: relative;
        h1{
            font-size: 40px;
            font-weight: 600;
            color: #24282b;
            margin-bottom: 20px;
            // text-transform: uppercase;
            position: relative;
            display: inline-block;
            transition: all 0.3s ease;
            span{
                transition: all 0.3s ease;
                color: #ffcd00;
            }
        }
        .detail{
            img{
                width: 100%;
                height: 400px;
                object-fit: cover;
                transition: all 0.3s ease;
                &:hover{
                    transform: scale(1.05);
                }
            }
            .team{
                display: flex;
                justify-content: center;
                // align-items: center;
                flex-direction: column;
                margin-bottom: 20px;
            }
        }
        p{
            font-weight: 600;
            font-size: 18px;
            text-align: justify;
            margin-top: 10px;
            margin-bottom: 5px;
        }
        small{
            color: #41464c;
            font-weight: 400;
            font-style: italic;
        }
    }
}