body {
    background-color: #fff;
    color: #000;
    font-family: 'Josefin Sans';
    .loader{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: #fff;
        visibility: hidden;
        display: flex;
        justify-content: center;
        z-index: 10000;
        transition: all 0.5s ease;
        opacity: 0;
        &.active{
            opacity: 1;
            visibility: visible;
        }
        @keyframes blink {
            0% {
                opacity: 0.5;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0.5;
            }
        }
        img{
            margin-top: -75px;
            animation: blink 1s linear infinite;
        }
    }
    .navbar-main {
        height: 100px;
        top: 0;
        left: 0;
        width: 100%;
        background-color: transparent;
        overflow: hidden;
        display: flex;
        z-index: 1;
        justify-content: space-between;
        &.fixed-top{
            position: fixed;
            background-color: white;
            box-shadow: 0 0 10px rgba(0,0,0,0.2);
            height: 70px;
            transition: all 0.3s ease;
            z-index: 900;
            .mobile-burger{
                top: 3px;
            }
            .mobile-menu{
                margin-top: 69px;
                height: calc(100vh - 70px);
                width: 100vw;
            }
        }
        .mobile-menu{
            position: fixed;
            height: calc(100vh - 100px);
            width: 115vw;
            margin-top: 99px;
            z-index: 1000;
            background: white;
            margin-left: -3rem;
            ul {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                li {
                    list-style: none;
                    padding: 0 15px;
                    margin-bottom: 20px;
                    a {
                        text-decoration: none;
                        color: #24282b;
                        font-size: 18px;
                        font-weight: 700;
                        padding: 5px;
                        transition: all 0.3s ease;
                        position: relative;
                        text-transform: uppercase;
                        &::before {
                            content: '';
                            position: absolute;
                            height: 100%;
                            top: 0;
                            left: 0;
                            width: 0%;
                            background-color: #ffcd00;
                            transition: all 0.3s ease;
                            border-radius: 2px;
                            opacity: 0.5;
                            z-index: -1;
                        }
                        &:hover {
                            &::before {
                                width: 50%;
                            }
                        }
                        &.active {
                            &::before {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
        .navbar-logo {
            img {
                height: 100%;
                width: auto;
            }
        }
        .navbar-links {
            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                li {
                    list-style: none;
                    padding: 0 15px;
                    a {
                        text-decoration: none;
                        color: #24282b;
                        font-size: 14px;
                        font-weight: 700;
                        padding: 5px;
                        transition: all 0.3s ease;
                        position: relative;
                        text-transform: uppercase;
                        &::before {
                            content: '';
                            position: absolute;
                            height: 100%;
                            top: 0;
                            left: 0;
                            width: 0%;
                            background-color: #ffcd00;
                            transition: all 0.3s ease;
                            border-radius: 2px;
                            opacity: 0.5;
                            z-index: -1;
                        }
                        &:hover {
                            &::before {
                                width: 50%;
                            }
                        }
                        &.active {
                            &::before {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }
        .mobile-burger{
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: 10px;
            cursor: pointer;
            position: relative;
            top: 0;
            .burger-line{
                width: 100%;
                height: 3px;
                background-color: #41464c;
                margin: 4px 0;
                &:nth-child(1){
                    transition: all 0.3s ease;
                }
                &:nth-child(2){
                    transition: all 0.3s ease;
                }
            }
            &.close{
                .burger-line{
                   // convert to X
                    &:nth-child(1){
                        transition: all 0.3s ease;
                        transform: rotate(45deg) translate(8px, 0);
                    }
                    &:nth-child(2){
                        transition: all 0.3s ease;
                        transform: rotate(-45deg) translate(7px, 0);
                    }
                    &:nth-child(3){
                        opacity: 0;
                    }
                }
            }
        }
    }
    .footer{
        min-height: 250px;
        background-color: #ffcd00;
        color: #24282b;
        p{
            font-size: 18px;
        }
        a{
            color: #24282b;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
                i{
                    color: #24282b;
                }
            }
        }
        button{
            background-color: #24282b;
        }
    }
    .body {
        @import './home.scss';
        @import './about.scss';
        @import './work.scss';
        @import './services.scss';
        @import './work-details.scss';
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    body:has(div.mobile-menu.active){
        overflow: hidden;
    }
}
