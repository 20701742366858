@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&display=swap');
@font-face {
    font-family: 'Josefin Sans';
    src: url(./assets/fonts/JosefinSans-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Josefin Sans';
    src: url(./assets/fonts/JosefinSans-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Josefin Sans';
    src: url(./assets/fonts/JosefinSans-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Josefin Sans';
    src: url(./assets/fonts/JosefinSans-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}
@font-face {
    font-family: 'Josefin Sans';
    src: url(./assets/fonts/JosefinSans-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Josefin Sans';
    src: url(./assets/fonts/JosefinSans-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Josefin Sans';
    src: url(./assets/fonts/JosefinSans-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Josefin Sans';
    src: url(./assets/fonts/JosefinSans-SemiBoldItalic.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Josefin Sans';
    src: url(./assets/fonts/JosefinSans-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Josefin Sans';
    src: url(./assets/fonts/JosefinSans-ThinItalic.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@import "~bootstrap/scss/bootstrap";
@import "./scss/all";

